const fetchUrl = window.config?.VUE_APP_API_URL || process.env?.VUE_APP_API_URL;
const liveApiUrl = window.config?.VUE_APP_LIVE_URL || process.env?.VUE_APP_LIVE_URL;

export const validateEventId = (eventId: string) => {
    return !!eventId;
};

export const fetchEventById = async (isShared: boolean, version: string, eventId: string) => {
    // Replaced by the entrypoint script
    let _API_KEY = '48eb23a7-44bf-4f96-9d02-25a3160222ad';
    if (_API_KEY.includes('API_KEY')) {
        _API_KEY = window.config?.VUE_APP_PUB_API_KEY || process.env.VUE_APP_PUB_API_KEY;
    }
    const API_KEY = _API_KEY;

    let fetchParams = '';

    let fetchPath = `api/v1/event/${eventId}`;
    if (isShared) {
        fetchParams += `shared=${isShared}`;
    } else if (version !== '') {
        fetchParams = `version=${version}`;
    }
    if (fetchParams !== '') {
        fetchPath += `?${fetchParams}`;
    }
    try {
        if (validateEventId(eventId)) {
            const response = await fetch(`${fetchUrl}/${fetchPath}`, {
                headers: { Authorization: `Bearer ${API_KEY}` }
            });
            const data = await response.json();
            data.startDate = new Date(data.startDate);
            data.endDate = new Date(data.endDate);
            return data;
        } else {
            throw Error(
                `Invalid Event ID URL parameter: ${eventId}`
            );
        }
    } catch (error) {
        return Error(error.message);
    }
};

export const fetchEvent = async () => {
    const path = window.location.pathname.substring(1);
    const pathStrings = path.split('/');
    let eventId = pathStrings[0];
    let isShared = false;
    let version = '';

    if (pathStrings[0] === 'shared') {
        isShared = true;
        eventId = pathStrings[1];
    } else if (pathStrings.length === 2) {
        version = pathStrings[1];
    }

    return fetchEventById(isShared, version, eventId);
};

export const fetchProgram = async (canvasProgramId: string) => {
    // Replaced by the entrypoint script
    let _API_KEY = '48eb23a7-44bf-4f96-9d02-25a3160222ad';
    if (_API_KEY.includes('API_KEY')) {
        _API_KEY = window.config?.VUE_APP_PUB_API_KEY || process.env.VUE_APP_PUB_API_KEY;
    }
    const API_KEY = _API_KEY;

    const fetchPath = `api/v1/live-fire/program/${canvasProgramId}`;
    try {
        const response = await fetch(`${fetchUrl}/${fetchPath}`, {
            headers: { Authorization: `Bearer ${API_KEY}` }
        });
        return await response.json();
    } catch (error) {
        return Error(error.message);
    }
};

export const getToken = async (canvasProgramId: string) => {
    // Replaced by the entrypoint script
    let _API_KEY = '44824ec8-1a0e-4024-92c4-d1475fd80f81';
    if (_API_KEY.includes('API_KEY')) {
        _API_KEY = window.config?.VUE_APP_API_KEY || process.env.VUE_APP_API_KEY;
    }
    const API_KEY = _API_KEY;
    const fetchPath = 'api/v1/token';
    try {
        const key = canvasProgramId + API_KEY;
        const response = await fetch(`${liveApiUrl}/${fetchPath}?key=${key}`, {
            headers: { Authorization: `Bearer ${API_KEY}` },
            method: 'GET'
        });
        return await response.json();
    } catch (error) {
        return Error(error.message);
    }
};
